/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  ChangePentestMeetingDateRequest,
  CreatePentestProjectRequest,
  FormResultForNullAndUpdatePentestProjectErrors,
  FormResultForSingleUuidAndCreatePentestProjectErrors,
  OptionalForCustomerFullPentestProject,
  OptionalForFullPentestProject,
  UpdatePentestProjectRequest,
} from '../models/index';

export interface ChangePentestDebriefDateRequest {
    uuid: string;
    ChangePentestMeetingDateRequest: ChangePentestMeetingDateRequest;
}

export interface ChangePentestDialogDateRequest {
    uuid: string;
    ChangePentestMeetingDateRequest: ChangePentestMeetingDateRequest;
}

export interface ChangePentestKickoffDateRequest {
    uuid: string;
    ChangePentestMeetingDateRequest: ChangePentestMeetingDateRequest;
}

export interface ChangePentestPresentationDateRequest {
    uuid: string;
    ChangePentestMeetingDateRequest: ChangePentestMeetingDateRequest;
}

export interface CreatePentestProjectOperationRequest {
    CreatePentestProjectRequest: CreatePentestProjectRequest;
}

export interface DeletePentestFilesDialogRequest {
    uuid: string;
}

export interface DeletePentestFilesKickoffRequest {
    uuid: string;
}

export interface DeletePentestFilesPresentationRequest {
    uuid: string;
}

export interface DeletePentestFilesReportRequest {
    uuid: string;
}

export interface DeletePentestReportRequest {
    uuid: string;
}

export interface GetPentestProjectCustomerRequest {
    uuid: string;
}

export interface GetPentestProjectInternalRequest {
    uuid: string;
}

export interface UpdatePentestProjectOperationRequest {
    uuid: string;
    UpdatePentestProjectRequest: UpdatePentestProjectRequest;
}

export interface UploadPentestFilesDialogRequest {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestFilesKickoffRequest {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestFilesPresentationRequest {
    uuid: string;
    file_name: string;
    body: Blob;
}

export interface UploadPentestFilesReportRequest {
    uuid: string;
    file_name: string;
    body: Blob;
}

/**
 * 
 */
export class PentestProjectsApi extends runtime.BaseAPI {

    /**
     * Changes a debrief\'s date  This method may only be called, if the debrief already has a date.
     * Changes a debrief\'s date
     */
    async changePentestDebriefDateRaw(requestParameters: ChangePentestDebriefDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling changePentestDebriefDate().'
            );
        }

        if (requestParameters['ChangePentestMeetingDateRequest'] == null) {
            throw new runtime.RequiredError(
                'ChangePentestMeetingDateRequest',
                'Required parameter "ChangePentestMeetingDateRequest" was null or undefined when calling changePentestDebriefDate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}/debrief-date`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ChangePentestMeetingDateRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Changes a debrief\'s date  This method may only be called, if the debrief already has a date.
     * Changes a debrief\'s date
     */
    async changePentestDebriefDate(requestParameters: ChangePentestDebriefDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePentestDebriefDateRaw(requestParameters, initOverrides);
    }

    /**
     * Changes a dialog\'s date  This method may only be called, if the dialog already has a date.
     * Changes a dialog\'s date
     */
    async changePentestDialogDateRaw(requestParameters: ChangePentestDialogDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling changePentestDialogDate().'
            );
        }

        if (requestParameters['ChangePentestMeetingDateRequest'] == null) {
            throw new runtime.RequiredError(
                'ChangePentestMeetingDateRequest',
                'Required parameter "ChangePentestMeetingDateRequest" was null or undefined when calling changePentestDialogDate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}/dialog-date`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ChangePentestMeetingDateRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Changes a dialog\'s date  This method may only be called, if the dialog already has a date.
     * Changes a dialog\'s date
     */
    async changePentestDialogDate(requestParameters: ChangePentestDialogDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePentestDialogDateRaw(requestParameters, initOverrides);
    }

    /**
     * Changes a kickoff\'s date  This method may only be called, if the kickoff already has a date.
     * Changes a kickoff\'s date
     */
    async changePentestKickoffDateRaw(requestParameters: ChangePentestKickoffDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling changePentestKickoffDate().'
            );
        }

        if (requestParameters['ChangePentestMeetingDateRequest'] == null) {
            throw new runtime.RequiredError(
                'ChangePentestMeetingDateRequest',
                'Required parameter "ChangePentestMeetingDateRequest" was null or undefined when calling changePentestKickoffDate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}/kickoff-date`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ChangePentestMeetingDateRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Changes a kickoff\'s date  This method may only be called, if the kickoff already has a date.
     * Changes a kickoff\'s date
     */
    async changePentestKickoffDate(requestParameters: ChangePentestKickoffDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePentestKickoffDateRaw(requestParameters, initOverrides);
    }

    /**
     * Changes a presentation\'s date  This method may only be called, if the presentation already has a date.
     * Changes a presentation\'s date
     */
    async changePentestPresentationDateRaw(requestParameters: ChangePentestPresentationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling changePentestPresentationDate().'
            );
        }

        if (requestParameters['ChangePentestMeetingDateRequest'] == null) {
            throw new runtime.RequiredError(
                'ChangePentestMeetingDateRequest',
                'Required parameter "ChangePentestMeetingDateRequest" was null or undefined when calling changePentestPresentationDate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}/presentation-date`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['ChangePentestMeetingDateRequest'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Changes a presentation\'s date  This method may only be called, if the presentation already has a date.
     * Changes a presentation\'s date
     */
    async changePentestPresentationDate(requestParameters: ChangePentestPresentationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePentestPresentationDateRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a new pentest project
     * Creates a new pentest project
     */
    async createPentestProjectRaw(requestParameters: CreatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSingleUuidAndCreatePentestProjectErrors>> {
        if (requestParameters['CreatePentestProjectRequest'] == null) {
            throw new runtime.RequiredError(
                'CreatePentestProjectRequest',
                'Required parameter "CreatePentestProjectRequest" was null or undefined when calling createPentestProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreatePentestProjectRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new pentest project
     * Creates a new pentest project
     */
    async createPentestProject(requestParameters: CreatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSingleUuidAndCreatePentestProjectErrors> {
        const response = await this.createPentestProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a project\'s dialog presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s dialog presentation
     */
    async deletePentestFilesDialogRaw(requestParameters: DeletePentestFilesDialogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deletePentestFilesDialog().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/dialog`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project\'s dialog presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s dialog presentation
     */
    async deletePentestFilesDialog(requestParameters: DeletePentestFilesDialogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentestFilesDialogRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a project\'s kickoff presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s kickoff presentation
     */
    async deletePentestFilesKickoffRaw(requestParameters: DeletePentestFilesKickoffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deletePentestFilesKickoff().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/kickoff`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project\'s kickoff presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s kickoff presentation
     */
    async deletePentestFilesKickoff(requestParameters: DeletePentestFilesKickoffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentestFilesKickoffRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a project\'s management presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s management presentation
     */
    async deletePentestFilesPresentationRaw(requestParameters: DeletePentestFilesPresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deletePentestFilesPresentation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/presentation`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project\'s management presentation  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s management presentation
     */
    async deletePentestFilesPresentation(requestParameters: DeletePentestFilesPresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentestFilesPresentationRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a project\'s report  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s report
     */
    async deletePentestFilesReportRaw(requestParameters: DeletePentestFilesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deletePentestFilesReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/report`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project\'s report  This endpoint only deletes the file.  It does **not** re-open a task or notify a customer.
     * Deletes a project\'s report
     */
    async deletePentestFilesReport(requestParameters: DeletePentestFilesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentestFilesReportRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a project\'s report to be re-uploaded through karla
     * Deletes a project\'s report to be re-uploaded through karla
     */
    async deletePentestReportRaw(requestParameters: DeletePentestReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deletePentestReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}/report`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project\'s report to be re-uploaded through karla
     * Deletes a project\'s report to be re-uploaded through karla
     */
    async deletePentestReport(requestParameters: DeletePentestReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePentestReportRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectCustomerRaw(requestParameters: GetPentestProjectCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForCustomerFullPentestProject>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestProjectCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectCustomer(requestParameters: GetPentestProjectCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForCustomerFullPentestProject> {
        const response = await this.getPentestProjectCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectInternalRaw(requestParameters: GetPentestProjectInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullPentestProject>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getPentestProjectInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a single pentest project  If the project doesn\'t exist, `null` will be returned. However, if the project does exist but is not of kind `Pentest`, a 400 will be returned.
     * Retrieves a single pentest project
     */
    async getPentestProjectInternal(requestParameters: GetPentestProjectInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullPentestProject> {
        const response = await this.getPentestProjectInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a pentest project
     * Updates a pentest project
     */
    async updatePentestProjectRaw(requestParameters: UpdatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndUpdatePentestProjectErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling updatePentestProject().'
            );
        }

        if (requestParameters['UpdatePentestProjectRequest'] == null) {
            throw new runtime.RequiredError(
                'UpdatePentestProjectRequest',
                'Required parameter "UpdatePentestProjectRequest" was null or undefined when calling updatePentestProject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/pentest/projects/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['UpdatePentestProjectRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Updates a pentest project
     * Updates a pentest project
     */
    async updatePentestProject(requestParameters: UpdatePentestProjectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndUpdatePentestProjectErrors> {
        const response = await this.updatePentestProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a project\'s dialog presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s dialog presentation
     */
    async uploadPentestFilesDialogRaw(requestParameters: UploadPentestFilesDialogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestFilesDialog().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestFilesDialog().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestFilesDialog().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/dialog`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uploads a project\'s dialog presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s dialog presentation
     */
    async uploadPentestFilesDialog(requestParameters: UploadPentestFilesDialogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadPentestFilesDialogRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads a project\'s kickoff presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s kickoff presentation
     */
    async uploadPentestFilesKickoffRaw(requestParameters: UploadPentestFilesKickoffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestFilesKickoff().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestFilesKickoff().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestFilesKickoff().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/kickoff`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uploads a project\'s kickoff presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s kickoff presentation
     */
    async uploadPentestFilesKickoff(requestParameters: UploadPentestFilesKickoffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadPentestFilesKickoffRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads a project\'s management presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s management presentation
     */
    async uploadPentestFilesPresentationRaw(requestParameters: UploadPentestFilesPresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestFilesPresentation().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestFilesPresentation().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestFilesPresentation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/presentation`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uploads a project\'s management presentation  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s management presentation
     */
    async uploadPentestFilesPresentation(requestParameters: UploadPentestFilesPresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadPentestFilesPresentationRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads a project\'s report  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s report
     */
    async uploadPentestFilesReportRaw(requestParameters: UploadPentestFilesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling uploadPentestFilesReport().'
            );
        }

        if (requestParameters['file_name'] == null) {
            throw new runtime.RequiredError(
                'file_name',
                'Required parameter "file_name" was null or undefined when calling uploadPentestFilesReport().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uploadPentestFilesReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['file_name'] != null) {
            queryParameters['file_name'] = requestParameters['file_name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/octet-stream';

        const response = await this.request({
            path: `/api/frontend/v1/admin/pentest/projects/{uuid}/files/report`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Uploads a project\'s report  This endpoint only creates the file and sets its visibility.  It does **not** finish a task or notify a customer.
     * Uploads a project\'s report
     */
    async uploadPentestFilesReport(requestParameters: UploadPentestFilesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadPentestFilesReportRaw(requestParameters, initOverrides);
    }

}
