import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import HeadingLayout from "src/components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Button } from "src/components/base/button";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FullUser } from "src/api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Badge } from "src/components/base/badge";
import { kebabCase } from "lodash";
import DialogUserGroups, { DialogUserGroupsAdditional } from "src/components/dialogs/admin/dialog-user-groups";
import CreateUserDialog from "src/components/dialogs/admin/dialog-create-user";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Dialog, DialogActions, DialogBody } from "src/components/base/dialog";
import Form from "src/components/base/form";
import { Field, FieldGroup, Fieldset, RequiredLabel } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { useForm } from "@tanstack/react-form";

/**
 * The properties for {@link UserManagement}
 */
export type UserManagementProps = {};

/**
 * The user management
 */
function UserManagement(props: UserManagementProps) {
    const [t] = useTranslation("admin-user-management");
    const [tg] = useTranslation();

    const [users, setUsers] = React.useState<Array<FullUser>>([]);

    const [openNewUserDialog, setOpenNewUserDialog] = React.useState(false);
    // The internal groups of the user that should be edited
    const [openEditGroupsDialog, setOpenEditGroupsDialog] = React.useState<DialogUserGroupsAdditional>();
    const [openSetKrakenUuidDialog, setOpenSetKrakenUuidDialog] = React.useState<FullUser>();

    const krakenForm = useForm({
        defaultValues: {
            krakenUuid: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value }) => {
            if (!openSetKrakenUuidDialog) {
                return;
            }

            const res = await Api.admin.users.setKrakenUuid(openSetKrakenUuidDialog.uuid, value.krakenUuid.trim());

            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setOpenSetKrakenUuidDialog(undefined);
        },
    });

    /**
     * Refresh the user list
     */
    const refreshUsers = () => {
        Api.admin.users.all().then((res) =>
            res.match(
                (users) => {
                    users.list.sort((a, b) => a.display_name.localeCompare(b.display_name));
                    setUsers(users.list);
                },
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => refreshUsers(), [openNewUserDialog, openEditGroupsDialog, openSetKrakenUuidDialog]);

    return (
        <>
            <HeadingLayout
                heading={tg("admin.user-management.heading")}
                headingChildren={
                    <Button type={"button"} onClick={() => setOpenNewUserDialog(true)}>
                        <PlusIcon />
                        {tg("admin.user-management.button")}
                    </Button>
                }
            >
                <Table className={"text-zinc-800 dark:text-zinc-200"} dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("label.name")}</TableHeader>
                            <TableHeader>{tg("label.mail")}</TableHeader>
                            <TableHeader>{tg("label.role")}</TableHeader>
                            <TableHeader>{tg("label.groups")}</TableHeader>
                            <TableHeader>{t("label.kraken-uuid")}</TableHeader>
                            <TableHeader className={"relative w-0"}>
                                <span className={"sr-only"}>{tg("Action")}</span>
                            </TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.uuid}>
                                <TableCell>{user.display_name}</TableCell>
                                <TableCell>{user.mail}</TableCell>
                                <TableCell>{tg(user.permissions.role)}</TableCell>
                                <TableCell>
                                    {user.permissions.role === "Internal" ? (
                                        <div className={"flex flex-wrap gap-1"}>
                                            {Object.entries(user.permissions.groups).map(
                                                ([key, value]) =>
                                                    value && (
                                                        <Badge key={key} color={"blue"}>
                                                            {tg(`label.${kebabCase(key)}`)}
                                                        </Badge>
                                                    ),
                                            )}
                                        </div>
                                    ) : undefined}
                                </TableCell>
                                <TableCell>{user.kraken_uuid && <CheckIcon className={"size-5"} />}</TableCell>
                                <TableCell>
                                    <Dropdown>
                                        <DropdownButton plain={true} aria-label={tg("accessibility.open-actions")}>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            {user.permissions.role === "Internal" && (
                                                <>
                                                    <DropdownItem onClick={() => setOpenSetKrakenUuidDialog(user)}>
                                                        <DropdownLabel>{t("button.set-kraken-uuid")}</DropdownLabel>
                                                    </DropdownItem>

                                                    <DropdownItem
                                                        onClick={() => {
                                                            user.permissions.role === "Internal" &&
                                                                setOpenEditGroupsDialog({
                                                                    uuid: user.uuid,
                                                                    groups: user.permissions.groups,
                                                                });
                                                        }}
                                                    >
                                                        <DropdownLabel>{tg("button.change-groups")}</DropdownLabel>
                                                    </DropdownItem>
                                                </>
                                            )}
                                            {user.permissions.role === "Customer" && (
                                                <DropdownItem
                                                    onClick={() => {
                                                        Api.admin.users.resetCredentials(user.uuid).then((res) =>
                                                            res.match(
                                                                (res) => {
                                                                    if (res.result === "Ok") {
                                                                        navigator.clipboard
                                                                            .writeText(res.value.link)
                                                                            .then(() =>
                                                                                toast.success("copied to clipboard"),
                                                                            );
                                                                    } else {
                                                                        if (res.error.not_local) {
                                                                            toast.error(
                                                                                "Can not reset the credentials of a non-local user",
                                                                            );
                                                                        }
                                                                    }
                                                                },
                                                                (err) => toast.error(err.message),
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    <DropdownLabel>{tg("button.reset-credentials")}</DropdownLabel>
                                                </DropdownItem>
                                            )}
                                            <DropdownItem>
                                                <DropdownLabel>{tg("button.delete")}</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </HeadingLayout>

            {openEditGroupsDialog && (
                <DialogUserGroups
                    open={true}
                    onClose={() => setOpenEditGroupsDialog(undefined)}
                    {...openEditGroupsDialog}
                />
            )}

            {openSetKrakenUuidDialog && (
                <Dialog open={true} onClose={() => setOpenSetKrakenUuidDialog(undefined)}>
                    <Form onSubmit={krakenForm.handleSubmit}>
                        <DialogBody>
                            <Fieldset>
                                <FieldGroup>
                                    <krakenForm.Field name={"krakenUuid"}>
                                        {(fieldApi) => (
                                            <Field>
                                                <RequiredLabel>{t("label.kraken-uuid")}</RequiredLabel>
                                                <Input
                                                    value={fieldApi.state.value}
                                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                />
                                            </Field>
                                        )}
                                    </krakenForm.Field>
                                </FieldGroup>
                            </Fieldset>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"}>{tg("button.submit")}</Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}

            {openNewUserDialog && (
                <CreateUserDialog open={openNewUserDialog} onClose={() => setOpenNewUserDialog(false)} />
            )}
        </>
    );
}

export const Route = createFileRoute("/_admin/a/user")({
    component: UserManagement,
});
