import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HeadingLayout from "src/components/base/heading-layout";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { FullKarlaSettings } from "src/api/generated";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Button } from "src/components/base/button";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Divider } from "src/components/base/divider";

/**
 * The properties for {@link KarlaKolumna}
 */
export type KarlaKolumnaProps = {};

/**
 * The settings for karla kolumna
 */
function KarlaKolumna(props: KarlaKolumnaProps) {
    const [t] = useTranslation("admin-settings-karla");

    const [settings, setSettings] = React.useState<FullKarlaSettings>();

    const form = useForm({
        defaultValues: {
            token: settings?.api_token ?? "",
            pentestTemplate: settings?.pentest_template ?? "",
        },
        // eslint-disable-next-line
        onSubmit: async (values) => {
            const res = await Api.admin.karlaSettings.set({
                api_token: values.value.token,
                pentest_template: values.value.pentestTemplate || null,
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            toast.success(t("toast.success"));

            await refresh();

            form.reset();
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);

    /**
     * Delete the existing api token
     */
    const deleteApiToken = async () => {
        const res = await Api.admin.karlaSettings.delete();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        toast.success(t("toast.token-deleted"));
    };

    /**
     * Refresh the settings
     */
    const refresh = async () => {
        const res = await Api.admin.karlaSettings.get();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setSettings(res.ok.optional);
    };

    useEffect(() => {
        refresh().then();
    }, []);

    return (
        <HeadingLayout heading={t("heading.karla")}>
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"token"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.api-token")}</Label>
                                    <div className={"mt-2 flex gap-3"}>
                                        <Input
                                            required={true}
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        />

                                        <Button
                                            outline={true}
                                            onClick={() => {
                                                const id = window.crypto.randomUUID();
                                                fieldApi.handleChange(id);
                                            }}
                                        >
                                            {t("button.generate")}
                                        </Button>
                                        <Button
                                            outline={true}
                                            onClick={async () => {
                                                await navigator.clipboard.writeText(fieldApi.state.value);
                                                toast.success(t("toast.copied-to-clipboard"));
                                            }}
                                        >
                                            <ClipboardDocumentIcon />
                                        </Button>
                                    </div>
                                </Field>
                            )}
                        </form.Field>
                        <form.Field
                            name={"pentestTemplate"}
                            validators={{
                                // eslint-disable-next-line jsdoc/require-jsdoc
                                onBlur: ({ value }) => {
                                    if (
                                        value.match(
                                            /^$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                                        ) === null
                                    )
                                        return t("error.invalid-uuid");
                                },
                            }}
                        >
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.pentest-report")}</Label>
                                    <Input
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                        onBlur={fieldApi.handleBlur}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <Divider />

                        <div className={"flex justify-end gap-2"}>
                            <Button
                                plain={true}
                                onClick={async () => {
                                    await deleteApiToken();
                                    await refresh();
                                }}
                            >
                                {t("button.delete")}
                            </Button>

                            <Button disabled={!isDirty} type={"submit"}>
                                {t("button.save")}
                            </Button>
                        </div>
                    </FieldGroup>
                </Fieldset>
            </Form>
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_admin/a/settings/karla-kolumna")({
    component: KarlaKolumna,
});
