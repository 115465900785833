/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  FullKarlaSettings,
  OptionalForFullKarlaSettings,
} from '../models/index';

export interface SetKarlaSettingsRequest {
    FullKarlaSettings: FullKarlaSettings;
}

/**
 * 
 */
export class KarlaSettingsApi extends runtime.BaseAPI {

    /**
     * Deletes the current karla settings, disabling karla interactions as a result
     * Deletes the current karla settings, disabling karla interactions as a result
     */
    async deleteKarlaSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/karla-settings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the current karla settings, disabling karla interactions as a result
     * Deletes the current karla settings, disabling karla interactions as a result
     */
    async deleteKarlaSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteKarlaSettingsRaw(initOverrides);
    }

    /**
     * Retrieves the current karla settings
     * Retrieves the current karla settings
     */
    async getKarlaSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForFullKarlaSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/karla-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the current karla settings
     * Retrieves the current karla settings
     */
    async getKarlaSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForFullKarlaSettings> {
        const response = await this.getKarlaSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets the current karla settings
     * Sets the current karla settings
     */
    async setKarlaSettingsRaw(requestParameters: SetKarlaSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['FullKarlaSettings'] == null) {
            throw new runtime.RequiredError(
                'FullKarlaSettings',
                'Required parameter "FullKarlaSettings" was null or undefined when calling setKarlaSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/karla-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['FullKarlaSettings'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the current karla settings
     * Sets the current karla settings
     */
    async setKarlaSettings(requestParameters: SetKarlaSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setKarlaSettingsRaw(requestParameters, initOverrides);
    }

}
